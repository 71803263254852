import approval from './approval'
import table from './table'

export default {
    data: 'Daten',
    details: 'Details',
    furtherDetails: 'Weitere Details',
    requests: 'Bestellung',
    history: 'Historie',
    offers: 'Angebote',
    statusTree: 'Statusbaum',
    progress: 'Verlauf',
    approvals: 'Genehmigungen',
    offerObtainment: 'Angebotseinholung',
    visibleUntil: 'Anfrage sichtbar bis',
    category: 'Kategorie',
    searchCategory: 'Kategorie suchen',
    costCenter: 'Kostenstelle',
    searchCostCenter: 'Kostenstelle suchen',
    costCenterManager: 'Kostenstellenverantwortlicher',
    alternativeRecipient: 'Alternativer Empfänger',
    recipient: 'Empfänger',
    recipient_room: 'Raumnummer',
    roomNumber: 'Raum {room}',
    quantity: 'Menge',
    unit: 'Einheit',
    offer: 'Angebot',
    searchOffer: 'Angebot suchen',
    orderId: 'Beschaffung',
    orderNumber: 'Beschaffung "{order_number}"',
    overview: 'Übersicht',
    orderOverview: 'Beschaffungsantrag',
    startTendering: 'Angebote einholen',
    tenderingExplanation: 'Um diese Beschaffung genehmigen zu lassen müssen Sie zuerst Angebote einholen. Tragen Sie ' +
        'dafür alle relevanten Daten in die Beschaffung ein. Danach können Sie über den Button "Angebote einholen" ' +
        'oben auf der Seite in den Status "Anfrage" wechseln. Ab dann können Lieferanten Ihre Beschaffung einsehen ' +
        'und Angebote abgeben.',
    reallyStartTendering: 'Wenn Sie die Angebotseinholung starten können die Daten zur Anfrage nicht mehr angepasst werden. Möchten Sie diese Beschaffungen mit den folgenden Daten zur Angebotseinholung freigeben?',

    cancelTendering: 'Anfrage abbrechen',
    reallyCancelTendering: 'Möchten Sie die Angebotseinholung wirklich abbrechen? Alle bisher eingegangenen Angebote werden gelöscht. Die Beschaffungsdaten können dann angepasst werden und die Angebotseinholung muss erneut gestartet werden.',

    finishTendering: 'Anfrage abschließen',
    reallyFinishTendering: 'Möchten Sie die Angebotseinholung wirklich abschließen? Nach Abschluss der Angebotseinholung können keine weiteren Angebote mehr eingereicht werden. Sie können danach die Angebote einsehen und ein Angebot auswählen.',

    subscribed: 'Von keinem Lieferanten abonniert|Von einem Lieferanten abonniert|Von {count} Lieferanten abonniert',
    tenderingBy: 'Angebot wird ausgeschrieben von',
    tenderingDescription: 'Beschreibung der Anfrage',
    tenderingCategory: 'Ausgeschriebene Kategorie',
    tenderingVisibleUntil: 'Anfrage sichtbar bis',
    noRestriction: 'Keine Einschränkung',
    file: 'Datei',

    sendOrder: 'Bestellen',
    cancelOrder: 'Stornieren',
    reallyCancelOrder: 'Möchten Sie die Beschaffung wirklich stornieren? Die Beschaffung kann nicht mehr bearbeitet werden.',
    requestOrder: 'Beantragen',
    noOfferSelected: 'Es wurde kein Angebot ausgewählt',
    noOfferCreated: 'Es wurde noch kein Angebot erstellt',
    orderDirty: 'Die Beschaffung wurde noch nicht im aktuellen Stand gespeichert',
    selectOfferHasNoOrganisation: 'Das Angebot hat keinen Lieferanten',
    selectOfferHasNoPositions: 'Das Angebot hat keine Positionen',
    selectOfferHasNoPrice: 'Das Angebot hat keinen Gesamtpreis',
    selectOfferPriceIsToHigh: 'Der Gesamtpreis des Angebots muss unter 100.000€ liegen, um in BEMA bestellt werden zu können',
    selectOfferPriceIsToHighForExpress: 'Der Gesamtpreis des Angebots muss für eine Eilbestellung unter 300€ liegen',
    pleaseCheckConditions: 'Bitte überprüfen Sie die folgenden Punkte:',
    orderRequestInvalid: 'Die Beschaffung kann nicht angefordert werden, da nicht alle notwendigen Daten vorhanden sind.',

    submittedOffers: {
        title: 'Eingereichte Angebote',
        number: 'Nr.',
        supplier: 'Lieferant',
        price: 'Betrag (Brutto)',
        date: 'Angebotsdatum',
        validUntil: 'Gültig bis',
        taxRate: 'MwSt.',
        priceType: 'Preisart',
        deliveryPrice: 'Lieferkosten',
        otherPrice: 'Sonstige Kosten',
        priceGross: 'Gesamtkosten',
        sumNet: 'Summe Netto',
        sumGross: 'Summe Brutto',
        remark: 'Bemerkung',
        showMore: 'Ein weiteres Angebot anzeigen | {count} weitere Angebote anzeigen',
        showLess: 'Ein weiteres Angebot aublenden | {count} weitere Angebote ausblenden',
        offersSubmittedCount: 'Es wurde kein Angebot eingeholt | Ein Angebot wurde eingeholt | Insgesamt wurden {count} Angebote eingeholt',

        priceTypes: {
            net: 'Netto',
            gross: 'Brutto',
        },

        select: 'Angebot {id} auswählen',
        preview: 'Angebot {id} ansehen',

        reason: 'Grund für die Auswahl',
        reasonText: 'Weitere Begründung',

        reasons: {
            lowestPrice: 'Preisgünstigstes Angebot',
            contractPartner: 'Rahmenvertragspartner',
            singleSupplier: 'Einzelanbieter',
            bestValue: 'Wirtschaftlichstes Angebot',
        }
    },

    positions: {
        title: 'Bestellwunsch',
        description: 'Beschreibung',
        number: 'Nr.',
        document: 'Dokumentupload',
        add: 'Position hinzufügen',
    },

    orderTypes: {
        order: 'Beschaffung',
        special: 'Sonderbestellung',
        express: 'Eilbestellung',
    },
    
    request: {
        title: 'Beschaffung',
        recipient: 'Empfänger',
        visibleUntil: 'Sichtbar bis',
        details: 'Details (Dokumentupload)',
    },
    
    order: {
        orderType: 'Art der Beschaffung',
        orderTypes: {
            firstOrder: 'Erstbeschaffung',
            replacementOrder: 'Ersatzbeschaffung',
            additionOrder: 'Ergänzungsbeschaffung',
            serviceOrder: 'Wartung',
            consumptionOrder: 'Verbrauchsmaterial',
        },
        followUpCosts: 'Fallen Folgekosten an?',
        followUpCostsType: 'Art der Folgekosten',
        annualCosts: 'Jährliche Kosten',
        funding: 'Finanzierung der Folgekosten aus Fachbereichsmitteln?',
        spaceAvailable: 'Raum verfügbar?',
        room: 'Raum',
        staffAvailable: 'Personal verfügbar?',
        staff: 'Zuständig für Bedienung',
        supportStaff: 'Wartung und technische Betreuung durch',
        additionalInfo: 'Zusatzinformationen',
        dangerousGoods: 'Gefahrengut beinhaltet?',
        structuralMeasures: 'Bauliche Maßnahmen für die Aufstellung des Gerätes',
    },

    special: {
        title: 'Sonderbestellung',
        orderReason: 'Begründung für Sonderbestellung',
        reasons: {
            other: 'Sonstiger Grund',
            onlyProvider: 'Einzelanbieter',
            favorableOpportunity: 'Günstige Gelegenheit',
            followUpOrder: 'Folgeauftrag',
        },
        orderReasonText: 'Weitere Begründung',
    },

    status: {
        open: 'Entwurf',
        correction: 'Korrektur',
        placeholder: '...',
        cost_center_approval: 'Genehmigung KST',
        data_center_approval: 'Genehmigung RZ',
        tendering: 'Anfrage',
        tendered: 'Angebotswahl',
        finance_approval: 'Genehmigung FZ',
        rejected: 'Abgelehnt',
        tech_department_approval: 'Genehmigung TA',
        additional_approvals_required: 'RZ/TA offen',
        closed: 'Abgeschlossen',
        canceled: 'Storniert'
    },

    units: {
        pcs: 'Stück',
        pack: 'Packung',
        carton: 'Karton',
        bag: 'Beutel',
        bundle: 'Bund',
        bottle: 'Flasche',
        canister: 'Kanister',
        liter: 'Liter',
        roll: 'Rolle',
        license: 'Lizenz',
        classroomLicense: 'Klassenraum-Lizenz',
        campusLicense: 'Campus-Lizenz',
        pack10: '10er-Pack',
        kg: 'kg',
        ml: 'ml',
        g: 'g',
        meter: 'Meter',
        m2: 'm²',
        m3: 'm³',
    },

    deleteOrder: 'Beschaffung löschen',
    printOrderRequest: 'PDF Beschaffungantrag',
    printOrderForm: 'PDF Bestellformular',
    exportingOrder: 'Dokument wird erstellt...',
    deleteOrderText:
        'Möchtest du die Beschaffung wirklich löschen? Dieser Vorgang kann nicht rückgängig gemacht werden.',
    deleteOrderSuccess: 'Beschaffung wurde erfolgreich gelöscht.',
    deleteOrderError: 'Die Beschaffung konnte nicht gelöscht werden.',

    confirmLeave: 'Sie haben nicht gespeicherte Änderungen. Möchten Sie die Seite wirklich verlassen?',

    table,
    approval
}