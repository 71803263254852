export default {
    title: 'Anfragen',
    id: 'ID',
    user_id: 'Ausgeschrieben von',
    category_id: 'Kategorie',
    positions: 'Positionen',
    positionDocument: 'Dokument',
    status: 'Status',
    tenderingActive: 'Anfrage aktiv',
    tenderOffer: 'Angebot abgegeben',
    acceptedOffer: 'Angebot angenommen',
    visible_until: 'Offen bis',
    created_at: 'Vom',
    createOffer: 'Angebot anlegen / bearbeiten',

    type: 'Beschaffungsart',
    qf_ownOnly: 'Meine Angebote',
    qf_activeRequests: 'Aktive Anfragen',
    qf_acceptedOffersOnly: 'Angenommene Angebote',
}
