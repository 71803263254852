export const releaseNotesDe: ReleaseNotes = [
    {
        release: "0.4.0",
        date: "2024-12-16",
        tags: [
            "Meilenstein 4", "Lieferanten"
        ],
        type: "minor",
        notes: [
            {
                "description": "Abschluss Meilenstein 4"
            },
            {
                "description": "Entfernung der Standardgeräte"
            },
            {
                "description": "Ansprechpersonen Anmeldung und Verwaltung"
            },
            {
                "description": "Angebotseinholung implementiert"
            },
            {
                "description": "Erste Benachrichtigungen implementiert"
            },
            {
                "description": "Anlegen von Angeboten als Ansprechperson oder Beschaffer"
            },
            {
                "description": "Auswahl von Angeboten für die Beschaffung"
            }
        ]
    },
    {
        release: "0.3.0",
        date: "2024-11-01",
        tags: [
            "Meilenstein 3", "Beschaffungen"
        ],
        type: "minor",
        notes: [
            {
                "description": "Abschluss Meilenstein 3"
            },
            {
                "description": "Grundmodell der Beschaffungen implementiert"
            },
            {
                "description": "Definition der Beschaffungsarten konzipiert"
            },
            {
                "description": "Beschaffungshistorie vorbereitet"
            },
            {
                "description": "Auswahlmöglichkeiten für Kostenstellen, Verantwortliche und Positionen implementiert"
            },
            {
                "description": "PDF Exporte vorbereitet"
            },

        ]
    },
    {
        release: "0.2.0",
        date: "2024-09-12",
        tags: [
            "Meilenstein 2", "Stammdaten"
        ],
        type: "minor",
        notes: [
            {
                "description": "Abschluss Meilenstein 2"
            },
            {
                "description": "Nutzerverwaltung erweitert"
            },
            {
                "description": "Kategorien implementiert"
            },
            {
                "description": "Kostenstellen implementiert"
            },
            {
                "description": "Standardgeräte implementiert"
            },
            {
                "description": "Mailvorlagen vorbereitet"
            }
        ]
    },
    {
        release: "0.1.0",
        date: "2024-07-31",
        tags: [
            "Meilienstein 1", "Grundsystem"
        ],
        type: "minor",
        notes: [
            {
                "description": "Abschluss Meilenstein 1"
            },
            {
                "description": "Fertigstellung des Grundsystems"
            },
            {
                "description": "Nutzerverwaltung implementiert"
            },
            {
                "description": "Berechtigungssystem vorbereitet"
            }
        ]
    },
    {
        release: "0.0.0",
        date: "2024-07-01",
        tags: [
            "Projektstart"
        ],
        type: "major",
        notes: [
            {
                "description": "Das Projekt wurde aufgesetzt. Die Entwicklung kann beginnen!"
            }
        ]
    }
]