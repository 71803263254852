import info from './info'
import menu from './menu'
import table from './table'

export default {
    userId: 'Nutzer {id}',
    newUser: 'Neuer Nutzer',
    createUser: 'Neuen Nutzer anlegen',

    createAndEditUser: 'Erstellen und weiter bearbeiten',


    deleteUser: 'Account löschen',
    deleteUserText:
        'Möchtest du den Account wirklich löschen? Dieser Vorgang kann nicht rückgängig gemacht werden.',
    deleteOtherUserText:
        'Möchtest du den Account von {name} wirklich löschen? Dieser Vorgang kann nicht rückgängig gemacht werden.',
    deleteOwnUserText:
        'Möchtest du deinen Account wirklich löschen? Dieser Vorgang kann nicht rückgängig gemacht werden.',
    deleteUserSuccess: 'Nutzer wurde erfolgreich gelöscht.',
    deleteUserError: 'Der Nutzer konnte nicht gelöscht werden.',
    invitationLinkCopied: 'Der Nutzer konnte nicht gelöscht werden.',

    userWasLiked: 'Nutzer hat ein Like von dir erhalten',
    userWasNotLiked: 'Nutzer hat kein Like von dir erhalten',

    impersonateSuccess: 'Erfolgreich als gewählter Nutzer unterwegs.',
    impersonateError: 'Identität konnte nicht übernommen werden.',
    impersonateBanner: 'Als {fullname} {organisation_name} unterwegs.',
    leaveImpersonationSuccess: 'Identität wurde erfolgreich zurückgesetzt.',
    leaveImpersonationError: 'Identität konnte nicht zurückgesetzt werden.',
    leaveImpersonationLink: 'Zurück',
    updateSuccess: 'Nutzer wurde erfolgreich gespeichert',
    welcome: 'Willkommen {name}!',

    firstname: 'Vorname',
    lastname: 'Nachname',
    room: 'Raumnummer',
    email: 'E-Mail',
    profileImage: 'Profilbild',
    profile_image_id: 'Profilbild ID',
    password: 'Passwort',
    passwordRepeat: 'Passwort wiederholen',
    confirmLeave: 'Sie haben nicht gespeicherte Änderungen. Möchten Sie die Seite wirklich verlassen?',

    costCenter: 'Kostenstelle',
    buyerReference: 'Leitweg ID',
    searchCostCenter: 'Kostenstelle suchen',

    roles: {
        title: 'Rollen',
        user: 'Mitarbeiter',
        maintenance: 'Stammdaten Verwaltung',
        supplier: 'Ansprechperson',
        admin: 'Administrator',
        data_center: 'Rechenzentrum',
        tech_department: 'Technische Abteilung',
    },

    info,
    menu,
    table
}