export const orders: TableModule = {
    name: "orders",
    route: "spa.orders",
    defaultOrder: {
        column: "id",
        direction: "desc"
    },
    columns: [
        {
            name: "type",
            sortable: true,
            mobile: true,
            pageLink: true,
            width: "w-[80px]"
        },
        {
            name: "order_number",
            sortable: true,
            mobile: true,
            pageLink: true,
            width: "md:w-[200px]"
        },
        {
            name: "category_id",
            sortable: true,
            breakpoint: "xl"
        },
        {
            name: "cost_center_id",
            sortable: true,
            breakpoint: "lg"
        },
        {
            name: "user_id",
            sortable: true,
            breakpoint: "xl"
        },
        {
            name: "created_at",
            sortable: true,
            breakpoint: "xl",
            width: "w-[100px]"
        },
        {
            name: "price",
            sortBy: "price",
            sortable: true,
            width: "w-[100px]"
        },
        {
            name: "status",
            sortBy: "status_id",
            sortable: true,
            mobile: true,
            width: "w-[150px]"
        },
    ],
    filters: [
        {
            name: "type",
            column: "orders.type",
            type: "select",
            reduced: true,
            options: [
                {
                    value: "order",
                    label: "Beschaffung"
                },
                {
                    value: "special",
                    label: "Sonderbestellung"
                },
                {
                    value: "express",
                    label: "Eilbestellung"
                }
            ]
        },
        {
            name: "order_number",
            column: "orders.order_number",
            type: "numeric",
            reduced: '[,]',
        },
        {
            name: "status",
            column: "orders.status_id",
            type: "select",
            options: () => $lara.get(
                useNuxtApp().$apiRoute('spa.statuses.index')
            ).then(response => response?.map(
                status => ({
                    value: status.id,
                    status: status.name,
                    label: useNuxtApp().$i18n.t('orders.status.' + status.name)
                }))
            ),
            reduced: true,
            multiple: true
        },
    ],
    quickFilters: [
        {
            name: "open",
            column: "orders.status_id",
            operator: "=",
            value: "1",
        }
    ],
    tools: [
        {
            name: "orders.create",
            isCreateAction: true,
            ability: ['create', 'orders']
        },
        {
            name: "general.export",
            icon: "heroicons:arrow-down-tray"
        },
        {
            name: "orders.delete",
            color: "rose",
            icon: "heroicons:trash"
        }
    ]
}