import { defineNuxtPlugin } from '#app/nuxt'
import { LazyTableFilterBoolean, LazyTableFilterNewFilterTemplate, LazyTableFilterNumeric, LazyTableFilterSelect, LazyTableFilterString, LazyTableToolCategoriesCreate, LazyTableToolCategoriesDelete, LazyTableToolCostCentersCreate, LazyTableToolCostCentersDelete, LazyTableToolCostCentersStatus, LazyTableToolGeneralExport, LazyTableToolLoginProvidersCreate, LazyTableToolOrdersCreate, LazyTableToolOrdersCreateExpress, LazyTableToolOrdersCreateOrder, LazyTableToolOrdersCreateSpecial, LazyTableToolOrdersDelete, LazyTableToolOrganisationsCreate, LazyTableToolOrganisationsToggle, LazyTableToolUsersCreate, LazyDashboardWidgetDemoOpenAi, LazyDashboardWidgetGeneralTableIndex, LazyLogOrderApproved, LazyLogSelectedOffer, LazyLogStartTendering, LazyLogStatusUpdate, LazyLogUserLiked, LazyBasicHeading, LazyBasicText, LazySubheading, LazyIcon } from '#components'
const lazyGlobalComponents = [
  ["TableFilterBoolean", LazyTableFilterBoolean],
["TableFilterNewFilterTemplate", LazyTableFilterNewFilterTemplate],
["TableFilterNumeric", LazyTableFilterNumeric],
["TableFilterSelect", LazyTableFilterSelect],
["TableFilterString", LazyTableFilterString],
["TableToolCategoriesCreate", LazyTableToolCategoriesCreate],
["TableToolCategoriesDelete", LazyTableToolCategoriesDelete],
["TableToolCostCentersCreate", LazyTableToolCostCentersCreate],
["TableToolCostCentersDelete", LazyTableToolCostCentersDelete],
["TableToolCostCentersStatus", LazyTableToolCostCentersStatus],
["TableToolGeneralExport", LazyTableToolGeneralExport],
["TableToolLoginProvidersCreate", LazyTableToolLoginProvidersCreate],
["TableToolOrdersCreate", LazyTableToolOrdersCreate],
["TableToolOrdersCreateExpress", LazyTableToolOrdersCreateExpress],
["TableToolOrdersCreateOrder", LazyTableToolOrdersCreateOrder],
["TableToolOrdersCreateSpecial", LazyTableToolOrdersCreateSpecial],
["TableToolOrdersDelete", LazyTableToolOrdersDelete],
["TableToolOrganisationsCreate", LazyTableToolOrganisationsCreate],
["TableToolOrganisationsToggle", LazyTableToolOrganisationsToggle],
["TableToolUsersCreate", LazyTableToolUsersCreate],
["DashboardWidgetDemoOpenAi", LazyDashboardWidgetDemoOpenAi],
["DashboardWidgetGeneralTableIndex", LazyDashboardWidgetGeneralTableIndex],
["LogOrderApproved", LazyLogOrderApproved],
["LogSelectedOffer", LazyLogSelectedOffer],
["LogStartTendering", LazyLogStartTendering],
["LogStatusUpdate", LazyLogStatusUpdate],
["LogUserLiked", LazyLogUserLiked],
["BasicHeading", LazyBasicHeading],
["BasicText", LazyBasicText],
["Subheading", LazySubheading],
["Icon", LazyIcon],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
