import { default as indexMbpzvyZq5CMeta } from "/home/forge/bema.farbcode.net/releases/20250124163020/client/pages/auth/company/index.vue?macro=true";
import { default as indexizrQ9bXYXuMeta } from "/home/forge/bema.farbcode.net/releases/20250124163020/client/pages/auth/index.vue?macro=true";
import { default as indexmPIMdlP12xMeta } from "/home/forge/bema.farbcode.net/releases/20250124163020/client/pages/auth/social/[organisation]/[provider]/index.vue?macro=true";
import { default as _91id_93COkd7WiFyUMeta } from "/home/forge/bema.farbcode.net/releases/20250124163020/client/pages/baseData/categories/[id].vue?macro=true";
import { default as indexFEL23koEnlMeta } from "/home/forge/bema.farbcode.net/releases/20250124163020/client/pages/baseData/categories/index.vue?macro=true";
import { default as _91id_93BTRy4NbfGZMeta } from "/home/forge/bema.farbcode.net/releases/20250124163020/client/pages/baseData/costCenters/[id].vue?macro=true";
import { default as indexw85Iuw633NMeta } from "/home/forge/bema.farbcode.net/releases/20250124163020/client/pages/baseData/costCenters/index.vue?macro=true";
import { default as indexotzaT7mh01Meta } from "/home/forge/bema.farbcode.net/releases/20250124163020/client/pages/baseData/mailTemplates/index.vue?macro=true";
import { default as baseDataCbrEjyZFe8Meta } from "/home/forge/bema.farbcode.net/releases/20250124163020/client/pages/baseData.vue?macro=true";
import { default as companyBaseDataWljKmKv4m7Meta } from "/home/forge/bema.farbcode.net/releases/20250124163020/client/pages/company/companyBaseData.vue?macro=true";
import { default as companynfCq4Xgr7IMeta } from "/home/forge/bema.farbcode.net/releases/20250124163020/client/pages/company.vue?macro=true";
import { default as indexX3deHwCv23Meta } from "/home/forge/bema.farbcode.net/releases/20250124163020/client/pages/index.vue?macro=true";
import { default as orderBaseDataednIG4Ou9dMeta } from "/home/forge/bema.farbcode.net/releases/20250124163020/client/pages/orders/[id]/orderBaseData.vue?macro=true";
import { default as orderDetailsl2CDNxSJHdMeta } from "/home/forge/bema.farbcode.net/releases/20250124163020/client/pages/orders/[id]/orderDetails.vue?macro=true";
import { default as orderHistoryJRxxdpDjRqMeta } from "/home/forge/bema.farbcode.net/releases/20250124163020/client/pages/orders/[id]/orderHistory.vue?macro=true";
import { default as orderOffersqUaWZHE4aKMeta } from "/home/forge/bema.farbcode.net/releases/20250124163020/client/pages/orders/[id]/orderOffers.vue?macro=true";
import { default as _91id_93kkMtCxT0RUMeta } from "/home/forge/bema.farbcode.net/releases/20250124163020/client/pages/orders/[id].vue?macro=true";
import { default as indexPBNBzFvZ2nMeta } from "/home/forge/bema.farbcode.net/releases/20250124163020/client/pages/orders/index.vue?macro=true";
import { default as organisationBaseData9SFzhEV8UgMeta } from "/home/forge/bema.farbcode.net/releases/20250124163020/client/pages/organisations/[id]/organisationBaseData.vue?macro=true";
import { default as _91id_93tAQXpduaFAMeta } from "/home/forge/bema.farbcode.net/releases/20250124163020/client/pages/organisations/[id].vue?macro=true";
import { default as indexFQIbJDWGNqMeta } from "/home/forge/bema.farbcode.net/releases/20250124163020/client/pages/organisations/index.vue?macro=true";
import { default as organisations0jH8f0dE71Meta } from "/home/forge/bema.farbcode.net/releases/20250124163020/client/pages/organisations.vue?macro=true";
import { default as dungeonbzMYyDI20qMeta } from "/home/forge/bema.farbcode.net/releases/20250124163020/client/pages/secret/index/dungeon.vue?macro=true";
import { default as inputsfivI8dIItTMeta } from "/home/forge/bema.farbcode.net/releases/20250124163020/client/pages/secret/index/inputs.vue?macro=true";
import { default as index0NZREkkyBbMeta } from "/home/forge/bema.farbcode.net/releases/20250124163020/client/pages/secret/index.vue?macro=true";
import { default as languageyBgzsM0q0zMeta } from "/home/forge/bema.farbcode.net/releases/20250124163020/client/pages/settings/language.vue?macro=true";
import { default as _91id_93COaOZnUGYOMeta } from "/home/forge/bema.farbcode.net/releases/20250124163020/client/pages/settings/loginProviders/[id].vue?macro=true";
import { default as indexAqNY720y7QMeta } from "/home/forge/bema.farbcode.net/releases/20250124163020/client/pages/settings/loginProviders/index.vue?macro=true";
import { default as _91_46_46_46slug_9325YWBGYaGBMeta } from "/home/forge/bema.farbcode.net/releases/20250124163020/client/pages/settings/mailTemplates/[name]/[...slug].vue?macro=true";
import { default as indexFQiT0BvigFMeta } from "/home/forge/bema.farbcode.net/releases/20250124163020/client/pages/settings/mailTemplates/index.vue?macro=true";
import { default as settingsYr5WUvbsgKMeta } from "/home/forge/bema.farbcode.net/releases/20250124163020/client/pages/settings.vue?macro=true";
import { default as tenderBaseDatacJCNGDhX9lMeta } from "/home/forge/bema.farbcode.net/releases/20250124163020/client/pages/tenders/[id]/tenderBaseData.vue?macro=true";
import { default as _91id_93QbY6JU51lQMeta } from "/home/forge/bema.farbcode.net/releases/20250124163020/client/pages/tenders/[id].vue?macro=true";
import { default as indexoXDbjUNHoiMeta } from "/home/forge/bema.farbcode.net/releases/20250124163020/client/pages/tenders/index.vue?macro=true";
import { default as userActivitiesjnTGX3OOw3Meta } from "/home/forge/bema.farbcode.net/releases/20250124163020/client/pages/users/[id]/userActivities.vue?macro=true";
import { default as userBaseDataouOnMfC71kMeta } from "/home/forge/bema.farbcode.net/releases/20250124163020/client/pages/users/[id]/userBaseData.vue?macro=true";
import { default as userCommunicationsrWpdoLXOgFMeta } from "/home/forge/bema.farbcode.net/releases/20250124163020/client/pages/users/[id]/userCommunications.vue?macro=true";
import { default as _91id_93WtIJ3IctOiMeta } from "/home/forge/bema.farbcode.net/releases/20250124163020/client/pages/users/[id].vue?macro=true";
import { default as indexuQ0vH6oHkqMeta } from "/home/forge/bema.farbcode.net/releases/20250124163020/client/pages/users/index.vue?macro=true";
import { default as usersvO7D7FKwl5Meta } from "/home/forge/bema.farbcode.net/releases/20250124163020/client/pages/users.vue?macro=true";
import { default as _91_46_46_46slug_93yeaEZdaqkbMeta } from "/home/forge/bema.farbcode.net/releases/20250124163020/client/pages/wiki/[...slug].vue?macro=true";
export default [
  {
    name: "auth-company",
    path: "/auth/company",
    meta: indexMbpzvyZq5CMeta || {},
    component: () => import("/home/forge/bema.farbcode.net/releases/20250124163020/client/pages/auth/company/index.vue").then(m => m.default || m)
  },
  {
    name: "auth",
    path: "/auth",
    meta: indexizrQ9bXYXuMeta || {},
    component: () => import("/home/forge/bema.farbcode.net/releases/20250124163020/client/pages/auth/index.vue").then(m => m.default || m)
  },
  {
    name: "auth-social-organisation-provider",
    path: "/auth/social/:organisation()/:provider()",
    meta: indexmPIMdlP12xMeta || {},
    component: () => import("/home/forge/bema.farbcode.net/releases/20250124163020/client/pages/auth/social/[organisation]/[provider]/index.vue").then(m => m.default || m)
  },
  {
    name: "baseData",
    path: "/baseData",
    meta: baseDataCbrEjyZFe8Meta || {},
    component: () => import("/home/forge/bema.farbcode.net/releases/20250124163020/client/pages/baseData.vue").then(m => m.default || m),
    children: [
  {
    name: "baseData-categories-id",
    path: "categories/:id()",
    meta: _91id_93COkd7WiFyUMeta || {},
    component: () => import("/home/forge/bema.farbcode.net/releases/20250124163020/client/pages/baseData/categories/[id].vue").then(m => m.default || m)
  },
  {
    name: "baseData-categories",
    path: "categories",
    meta: indexFEL23koEnlMeta || {},
    component: () => import("/home/forge/bema.farbcode.net/releases/20250124163020/client/pages/baseData/categories/index.vue").then(m => m.default || m)
  },
  {
    name: "baseData-costCenters-id",
    path: "costCenters/:id()",
    meta: _91id_93BTRy4NbfGZMeta || {},
    component: () => import("/home/forge/bema.farbcode.net/releases/20250124163020/client/pages/baseData/costCenters/[id].vue").then(m => m.default || m)
  },
  {
    name: "baseData-costCenters",
    path: "costCenters",
    meta: indexw85Iuw633NMeta || {},
    component: () => import("/home/forge/bema.farbcode.net/releases/20250124163020/client/pages/baseData/costCenters/index.vue").then(m => m.default || m)
  },
  {
    name: "baseData-mailTemplates",
    path: "mailTemplates",
    component: () => import("/home/forge/bema.farbcode.net/releases/20250124163020/client/pages/baseData/mailTemplates/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "company",
    path: "/company",
    meta: companynfCq4Xgr7IMeta || {},
    component: () => import("/home/forge/bema.farbcode.net/releases/20250124163020/client/pages/company.vue").then(m => m.default || m),
    children: [
  {
    name: "company-companyBaseData",
    path: "companyBaseData",
    component: () => import("/home/forge/bema.farbcode.net/releases/20250124163020/client/pages/company/companyBaseData.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "index",
    path: "/",
    component: () => import("/home/forge/bema.farbcode.net/releases/20250124163020/client/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "orders-id",
    path: "/orders/:id()",
    meta: _91id_93kkMtCxT0RUMeta || {},
    component: () => import("/home/forge/bema.farbcode.net/releases/20250124163020/client/pages/orders/[id].vue").then(m => m.default || m),
    children: [
  {
    name: "orders-id-orderBaseData",
    path: "orderBaseData",
    component: () => import("/home/forge/bema.farbcode.net/releases/20250124163020/client/pages/orders/[id]/orderBaseData.vue").then(m => m.default || m)
  },
  {
    name: "orders-id-orderDetails",
    path: "orderDetails",
    component: () => import("/home/forge/bema.farbcode.net/releases/20250124163020/client/pages/orders/[id]/orderDetails.vue").then(m => m.default || m)
  },
  {
    name: "orders-id-orderHistory",
    path: "orderHistory",
    component: () => import("/home/forge/bema.farbcode.net/releases/20250124163020/client/pages/orders/[id]/orderHistory.vue").then(m => m.default || m)
  },
  {
    name: "orders-id-orderOffers",
    path: "orderOffers",
    component: () => import("/home/forge/bema.farbcode.net/releases/20250124163020/client/pages/orders/[id]/orderOffers.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "orders",
    path: "/orders",
    meta: indexPBNBzFvZ2nMeta || {},
    component: () => import("/home/forge/bema.farbcode.net/releases/20250124163020/client/pages/orders/index.vue").then(m => m.default || m)
  },
  {
    name: organisations0jH8f0dE71Meta?.name,
    path: "/organisations",
    meta: organisations0jH8f0dE71Meta || {},
    component: () => import("/home/forge/bema.farbcode.net/releases/20250124163020/client/pages/organisations.vue").then(m => m.default || m),
    children: [
  {
    name: "organisations-id",
    path: ":id()",
    meta: _91id_93tAQXpduaFAMeta || {},
    component: () => import("/home/forge/bema.farbcode.net/releases/20250124163020/client/pages/organisations/[id].vue").then(m => m.default || m),
    children: [
  {
    name: "organisations-id-organisationBaseData",
    path: "organisationBaseData",
    component: () => import("/home/forge/bema.farbcode.net/releases/20250124163020/client/pages/organisations/[id]/organisationBaseData.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "organisations",
    path: "",
    meta: indexFQIbJDWGNqMeta || {},
    component: () => import("/home/forge/bema.farbcode.net/releases/20250124163020/client/pages/organisations/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "secret",
    path: "/secret",
    component: () => import("/home/forge/bema.farbcode.net/releases/20250124163020/client/pages/secret/index.vue").then(m => m.default || m),
    children: [
  {
    name: "secret-index-dungeon",
    path: "dungeon",
    component: () => import("/home/forge/bema.farbcode.net/releases/20250124163020/client/pages/secret/index/dungeon.vue").then(m => m.default || m)
  },
  {
    name: "secret-index-inputs",
    path: "inputs",
    component: () => import("/home/forge/bema.farbcode.net/releases/20250124163020/client/pages/secret/index/inputs.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "settings",
    path: "/settings",
    meta: settingsYr5WUvbsgKMeta || {},
    component: () => import("/home/forge/bema.farbcode.net/releases/20250124163020/client/pages/settings.vue").then(m => m.default || m),
    children: [
  {
    name: "settings-language",
    path: "language",
    component: () => import("/home/forge/bema.farbcode.net/releases/20250124163020/client/pages/settings/language.vue").then(m => m.default || m)
  },
  {
    name: "settings-loginProviders-id",
    path: "loginProviders/:id()",
    meta: _91id_93COaOZnUGYOMeta || {},
    component: () => import("/home/forge/bema.farbcode.net/releases/20250124163020/client/pages/settings/loginProviders/[id].vue").then(m => m.default || m)
  },
  {
    name: "settings-loginProviders",
    path: "loginProviders",
    meta: indexAqNY720y7QMeta || {},
    component: () => import("/home/forge/bema.farbcode.net/releases/20250124163020/client/pages/settings/loginProviders/index.vue").then(m => m.default || m)
  },
  {
    name: "settings-mailTemplates-name-slug",
    path: "mailTemplates/:name()/:slug(.*)*",
    meta: _91_46_46_46slug_9325YWBGYaGBMeta || {},
    component: () => import("/home/forge/bema.farbcode.net/releases/20250124163020/client/pages/settings/mailTemplates/[name]/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "settings-mailTemplates",
    path: "mailTemplates",
    meta: indexFQiT0BvigFMeta || {},
    component: () => import("/home/forge/bema.farbcode.net/releases/20250124163020/client/pages/settings/mailTemplates/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "tenders-id",
    path: "/tenders/:id()",
    meta: _91id_93QbY6JU51lQMeta || {},
    component: () => import("/home/forge/bema.farbcode.net/releases/20250124163020/client/pages/tenders/[id].vue").then(m => m.default || m),
    children: [
  {
    name: "tenders-id-tenderBaseData",
    path: "tenderBaseData",
    component: () => import("/home/forge/bema.farbcode.net/releases/20250124163020/client/pages/tenders/[id]/tenderBaseData.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "tenders",
    path: "/tenders",
    meta: indexoXDbjUNHoiMeta || {},
    component: () => import("/home/forge/bema.farbcode.net/releases/20250124163020/client/pages/tenders/index.vue").then(m => m.default || m)
  },
  {
    name: usersvO7D7FKwl5Meta?.name,
    path: "/users",
    meta: usersvO7D7FKwl5Meta || {},
    component: () => import("/home/forge/bema.farbcode.net/releases/20250124163020/client/pages/users.vue").then(m => m.default || m),
    children: [
  {
    name: "users-id",
    path: ":id()",
    meta: _91id_93WtIJ3IctOiMeta || {},
    component: () => import("/home/forge/bema.farbcode.net/releases/20250124163020/client/pages/users/[id].vue").then(m => m.default || m),
    children: [
  {
    name: "users-id-userActivities",
    path: "userActivities",
    component: () => import("/home/forge/bema.farbcode.net/releases/20250124163020/client/pages/users/[id]/userActivities.vue").then(m => m.default || m)
  },
  {
    name: "users-id-userBaseData",
    path: "userBaseData",
    component: () => import("/home/forge/bema.farbcode.net/releases/20250124163020/client/pages/users/[id]/userBaseData.vue").then(m => m.default || m)
  },
  {
    name: "users-id-userCommunications",
    path: "userCommunications",
    component: () => import("/home/forge/bema.farbcode.net/releases/20250124163020/client/pages/users/[id]/userCommunications.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "users",
    path: "",
    meta: indexuQ0vH6oHkqMeta || {},
    component: () => import("/home/forge/bema.farbcode.net/releases/20250124163020/client/pages/users/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "wiki-slug",
    path: "/wiki/:slug(.*)*",
    meta: _91_46_46_46slug_93yeaEZdaqkbMeta || {},
    component: () => import("/home/forge/bema.farbcode.net/releases/20250124163020/client/pages/wiki/[...slug].vue").then(m => m.default || m)
  }
]