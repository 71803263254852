export const tenders: TableModule = {
    name: "tenders",
    route: "spa.tenders",
    defaultOrder: {
        column: "visible_until",
        direction: "desc"
    },
    columns: [
        {
            name: "id",
            sortable: true,
            mobile: true,
            pageLink: true,
            breakpoint: "xl",
            width: "w-20"
        },
        {
            name: "user_id",
            sortable: true,
            breakpoint: "xl"
        },
        {
            name: "category_id",
            sortable: true,
            mobile: true,
            breakpoint: "xl"
        },
        {
            name: "positions",
        },
        {
            name: "visible_until",
            sortable: true,
            mobile: true,
            breakpoint: "xl",
            width: "w-40"
        },
        {
            name: "created_at",
            sortable: true,
            breakpoint: "xl",
            width: "w-40"
        },
        {
            name: "status"
        }
    ],
    filters: [
        {
            name: "type",
            column: "orders.type",
            type: "select",
            reduced: true,
            options: [
                {
                    value: "order",
                    label: "Beschaffung"
                },
                {
                    value: "special",
                    label: "Sonderbestellung"
                },
                {
                    value: "express",
                    label: "Eilbestellung"
                }
            ]
        }
    ],
    quickFilters: [
        {
            name: "qf_activeRequests",
            column: 'customFilterActiveRequests',
            value: true,
            operator: '=',
            default: true
        },
        {
            name: "qf_ownOnly",
            column: "customFilterOwnOnly",
            value: true,
            operator: "="
        },
        {
            name: "qf_acceptedOffersOnly",
            column: "customFilterAcceptedOffersOnly",
            value: true,
            operator: "="
        }
    ],
}