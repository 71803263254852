<template>
    <div>
        <!-- Profile dropdown -->
        <Menu
            v-if="authStore.userData"
            v-slot="{ open }"
            as="div"
            class="relative">
            <MenuButton class="-m-1.5 flex items-center p-1.5">
                <span class="sr-only">Open user menu</span>
                <div>
                    <div class="relative">
                        <img
                            v-if="authStore.userData.image_url"
                            class="size-8 object-cover rounded-full bg-gray-50"
                            :src="authStore.userData.image_url"
                            alt="">
                        <Icon
                            v-else
                            name="heroicons:user-circle"
                            class="h-8 w-8 text-gray-400 dark:text-gray-300" />
                        <span
                            v-if="versionMismatch"
                            class="w-2 h-2 bg-danger-500 block absolute bottom-0 right-0 rounded-full" />
                    </div>
                </div>
                <span
                    class="hidden lg:flex lg:items-center ml-4 text-left">
                    <span>
                        <span
                            class="block text-sm font-semibold leading-none text-gray-900 dark:text-gray-100"
                            aria-hidden="true">{{ authStore.userData.fullname }}</span>
                        <span class="leading-none text-sm text-gray-400 dark:text-gray-200">
                            {{ authStore.userData.organisation_name }}
                        </span>
                    </span>
                    <Icon
                        name="heroicons:chevron-down"
                        class="ml-2 h-5 w-5 text-gray-400 transition-transform duration-150 ease-in-out"
                        :class="{'rotate-180': open}"
                        aria-hidden="true" />
                </span>
            </MenuButton>
            <Transition
                enter-active-class="transition ease-out duration-100"
                enter-from-class="transform opacity-0 scale-95"
                enter-to-class="transform opacity-100 scale-100"
                leave-active-class="transition ease-in duration-75"
                leave-from-class="transform opacity-100 scale-100"
                leave-to-class="transform opacity-0 scale-95">
                <MenuItems
                    class="absolute right-0 z-10 mt-2.5 origin-top-right rounded-md bg-white dark:bg-gray-700 py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                    <NuxtLink :to="useLocalePath()('/users/' + authStore.userData.id + '/userBaseData')">
                        <BaseMenuItem
                            icon="heroicons:user"
                            icon-class="ml-6">
                            {{ $t('menu.myProfile') }}
                        </BaseMenuItem>
                    </NuxtLink>
                    <BaseMenuItem
                        icon="heroicons:information-circle"
                        icon-class="ml-6"
                        @click="showUserInfoModal = true">
                        <template #icon>
                            <span class="relative ml-6">
                                <Icon
                                    name="heroicons:information-circle"
                                    class="w-5 h-5" />

                                <span
                                    v-if="versionMismatch"
                                    class="w-2 h-2 bg-danger-500 block absolute bottom-0 right-0 rounded-full" />
                            </span>
                        </template>
                        {{ $t('menu.information') }}
                    </BaseMenuItem>
                    <NuxtLink
                        v-if="hasEscapeRoomFeature"
                        :to="useLocalePath()('/secret/dungeon')">
                        <BaseMenuItem
                            icon="fa6-solid:dungeon"
                            icon-class="ml-6">
                            {{ $t('menu.secret') }}
                        </BaseMenuItem>
                    </NuxtLink>
                    <BaseMenuItem
                        v-if="hasDarkFeature"
                        class="cursor-default"
                        @click="$event => $event.preventDefault()">
                        <template #icon>
                            <BaseSwitch
                                v-model="isDark"
                                name="dark-mode"
                                :icons="['heroicons:sun', 'heroicons:moon']" />
                        </template>
                        Darkmode
                    </BaseMenuItem>
                    <BaseMenuItem
                        v-if="hasMultiLanguageFeature"
                        class="cursor-default"
                        @click="$event => $event.preventDefault()">
                        <template #icon>
                            <BaseSwitch
                                v-model="languageToggle"
                                name="locale"
                                :icons="['circle-flags:de', 'circle-flags:en']" />
                        </template>
                        {{ $t(`locales.${lang}`) }}
                    </BaseMenuItem>
                    <BaseMenuItem
                        icon="heroicons:arrow-left-on-rectangle"
                        icon-class="ml-6"
                        @click="logout">
                        {{ $t('menu.logout') }}
                    </BaseMenuItem>
                </MenuItems>
            </Transition>
        </Menu>
        <UserInfoModal v-model="showUserInfoModal" />
    </div>
</template>

<script setup>
import {useLanguage} from "@/composables/useLanguage"
import {useAuthStore} from "@/store/auth"

const loading = ref(false)
const showUserInfoModal = ref(false)
const authStore = useAuthStore()
const {toggle: languageToggle, lang} = useLanguage()

const {versionMismatch} = useAppVersion()

const logout = async () => {
    loading.value = true
    await authStore.logout()
    loading.value = false
}

const hasDarkFeature = useFeature('darkMode')
const hasEscapeRoomFeature = useFeature('escapeRoom')
const hasMultiLanguageFeature = useFeature('multiLanguage')

let isDark = null

if (useFeature('darkMode')) {
    isDark = useDark({
        disableTransition: false
    })
}
</script>

<style scoped>

</style>