import table from './table'

export default {
    title: 'Anfrage',
    overview: 'Übersicht',
    request: 'Anfrage',
    offer: {
        title: 'Angebot',
        create: 'Angebot anlegen / bearbeiten',
    },
    positionDocument: 'Positionsdokument',
    updateOffer: 'Angebot anpassen',
    createOffer: 'Angebot abgeben',
    withdrawOffer: 'Angebot zurückziehen',
    mustRepresentsCompany: 'Angebote zu Anfragen können nur im Namen eines Unternehmens abgegeben werden.',
    withdrawOfferConfirm: 'Angebot wirklich zurückziehen? Dadurch gehen alle Angebotsdaten verloren. Angebote können neu erstellt oder bearbeitet werden.',

    positions: {
        title: 'Positionen',
        number: 'Nr.',
        description: 'Beschreibung',
        quantity: 'Anzahl',
        quantityUnit: 'Einheit',
        priceType: 'Brutto/Netto',
        taxRate: 'MwSt.',
        price: 'Preis',
    },

    table
}